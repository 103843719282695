interface Service {
  name: string,
  property: string,
  colour: string
}

export const services: Service[] = [
  { name: 'Energía', property: 'Costo_Energia', colour: 'var(--color-primary)' },
  { name: 'Agua', property: 'Costo_Agua', colour: '#688DF4' },
  { name: 'Gas', property: 'Costo_Gas', colour: '#F2C80F' },
  { name: 'Aseo', property: 'Costo_Aseo', colour: '#F5C5A2' },
  { name: 'Seguros', property: 'Costo_Seguros', colour: '#8D6FD1' },
  { name: 'Alumbrado', property: 'Costo_Alumbrado', colour: '#8B909A' },
  { name: 'Creditos', property: 'Costo_Creditos', colour: '#EE9E64' },
  { name: 'Saldos', property: 'Costo_Saldos', colour: '#FD625E' }
]