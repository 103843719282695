import React, { FC } from "react";
import { iconWarning } from "../assets/icons";
import { Report } from '../types';

export const Warning:FC<Report> = ({warning}) => {

  return (
    <>
      <div className="card warning">
        <div className="container-icon">
          <img className="icon" src={iconWarning} alt="" />
        </div>
        <p className="text">{warning?.map((element, index) => (
          <>
            {element}
            { index > 0 && <br /> }
          </>
        ))}</p>
      </div>
    </>
  )
}