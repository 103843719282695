import React, { useState, useEffect, useCallback, FC } from 'react';
import { ServiceType, Report, SaleServiceType } from '.././types';
import { services } from '../source';
import { AllServices, Calification, Service, Trees, Warning } from '.././views';
import { iconEnergy, iconWater, iconGas, iconLens, iconCar, iconPlane, iconHouse, iconPlanSura, iconSuraWhite } from '.././assets/icons';
import axios, { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { pointsToThousandsSTR } from '../utils/operations';

export const Main:FC = () => {

  const months: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

  const [report, setReport] = useState<Report>();
  const [renderInitial, setRenderInitial] = useState<boolean>(false);

  const { id } = useParams();

  const fetchData = useCallback(async() => {

    const { data }:AxiosResponse = await axios.get(`${process.env.REACT_APP_URL_API}/report/${id}`, {
      headers: {
          'Content-Type': 'application/json'
          // 'access_key': 
      }
    })
    const newReport: Report = {
      name: (data.data.Nickname !== null && data.data.Nickname !== '' ? data.data.Nickname : data.data.Cliente.split(' ')[0]).toLowerCase(),
      month: data.data.Mes,
      year: data.data.Año,
      plan: data.data.Nombre_Plan,
      address: data.data.Direccion,
      warning: data.data.Anomalias.split('¦ '),
      numberTrees: data.data.Arboles,
      currentMonth: (parseInt(data.data.Ahorro_Energia_Acumulada) || 0) + (parseInt(data.data.Ahorro_Agua_Acumulada) || 0) + (parseInt(data.data.Ahorro_Gas_Acumulada) || 0),
      lastMonth: (parseFloat(data.data.Ahorro_Energia_Acumulada_Past) || 0) + (parseFloat(data.data.Ahorro_Agua_Acumulada_Past) || 0) + (parseFloat(data.data.Ahorro_Gas_Acumulada_Past) || 0),
      savingEnergy: data.data.Ahorro_Energia_Acumulada === null ? null : data.data.Ahorro_Energia_Acumulada - data.data.Ahorro_Energia_Acumulada_Past,
      savingWater: data.data.Ahorro_Agua_Acumulada === null ? null : data.data.Ahorro_Agua_Acumulada - data.data.Ahorro_Agua_Acumulada_Past,
      savingGas: data.data.Ahorro_Gas_Acumulada === null ? null : data.data.Ahorro_Gas_Acumulada - data.data.Ahorro_Gas_Acumulada_Past,
      services: [],
      allServices: [],
      totalPrice: pointsToThousandsSTR(data.data.Total_Mes),
      sura: data.data.sura,
    };

    for (let i = 0; i < 3; i++) {
      const nameService: string = i === 0 ? 'Energía' : i === 1 ? 'Agua' : 'Gas'
      const iconService: string = i === 0 ? iconEnergy : i === 1 ? iconWater : iconGas
      const history: string = `Historico_${i === 0 ? 'E' : i === 1 ? 'A' : 'G'}`
      const consume: string = `Consumo_${i === 0 ? 'Energia' : i === 1 ? 'Agua' : 'Gas'}`
      const others: string = `${i === 0 ? 'Energia' : i === 1 ? 'Agua' : 'Gas'}_Otros_Hogares`
      const link: string = `Link_Factura_${i === 0 ? 'E' : i === 1 ? 'A' : 'G'}`
      const expires: string = `Vence_${i === 0 ? 'Energia' : i === 1 ? 'Agua' : 'Gas'}`
      if(data.data[consume] !== null) {
        const newService: ServiceType = {
          service: nameService,
          consume: data.data[consume],
          date: data.data[expires],
          icon: iconService,
          link: data.data[link],
          measure: i === 0 ? 'kWh' : 'mts3',
          historical: 100 - ((data.data[consume] / data.data[history]) * 100),
          others: 100 - ((data.data[consume] / data.data[others]) * 100)
        }
        newReport.services?.push(newService)
      }   
    }

    const percentagaBase: number = 100 / data.data.Total_Mes

    services.forEach((element, index) => {
      if(data.data[element.property] !== null) {
        const valuePercentage: number = Math.round(data.data[element.property] * percentagaBase);
        const newSaleService: SaleServiceType = {
          name: element.name,
          value: valuePercentage,
          colour: element.colour
        }
        newReport.allServices?.push(newSaleService)
      }
    });



    setReport(newReport);
    setRenderInitial(true)
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  

  return renderInitial ? (
    <section className="report">
      <article className="data-info">
        {/* <!-- Welcome --> */}
        <div className="card">
          <div className="description">
            <h5 className="bg-primary date">{months[(report?.month || 0) - 1]} {report?.year}</h5>
            <h3 className="color-primary client">¡Hola!</h3>
            <h4 className="text">HEMOS REVISADO TU FACTURA</h4>
          </div>
          <div className="icon">
            <img src={iconLens} alt="" />
          </div>
        </div>

        {/* <!-- Plan --> */}
        <div className="info-plan">
            {
              report?.sura ? (
                <div className='container-icon'>
                  <img src={iconPlanSura} alt="" />
                </div>
              ) : (
                <div className='container-icon'>
                  <img src={(report?.plan || '').includes('EN COCHE') ? iconCar : (report?.plan || '').includes('EN AVION') ? iconPlane : iconHouse} alt="" />
                </div>
              )
            }
          <span className="plan color-white">TU PLAN <span className="color-primary">{ report?.sura ? 'SURA' : report?.plan }</span></span>
        </div>

        {/* <!-- Consume --> */}
        <div className="card consume">
          <div className="row">
            <span></span>
            <div className='cols cols-sura header'>
              <span className="bg-primary main-title">MI CONSUMO VS</span>
              <span className="other-title">MI HISTÓRICO</span>
            </div>
          </div>
          {
            report?.services?.map((element, index) => (
              <Service key={`Services-Consume-${index}`} service={element.service} consume={element.consume} measure={element.measure} date={element.date} link={element.link} historical={element.historical} others={element.others} icon={element.icon} sura={report?.sura} />
            ))
          }
        </div>

        {/* <!-- Border --> */}
        <img style={{marginTop: '1rem'}} src="https://energyhome.blob.core.windows.net/piezasgraficas/Recurso_5.svg" alt="" />
        
      </article>
      
      <article className="more-info">

        <img className="icon-dots-primary" style={{marginBottom: '1rem'}} src="https://energyhome.blob.core.windows.net/piezasgraficas/Recurso_4.svg" alt="" />

        {/* Data */}
        <div className='data data-sura'>
          <Warning 
            warning={report?.warning}
          />
          <AllServices allServices={report?.allServices} totalPrice={report?.totalPrice} />

          <div className="contact">
            <a className="color-primary text link-tips" href='https://energymaster.co/energy-tips-2/' target='blank'>TIPS DE AHORRO</a>
            <img src={report?.sura ? iconSuraWhite : 'https://energyhome.blob.core.windows.net/piezasgraficas/Recurso_8.svg'} alt="" />
          </div>

        </div>
      </article>
    </section>
  ) : (<></>);
}
