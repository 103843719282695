import React, { FC } from "react";
import { ServiceType } from '../types';
import { applauseEmoji, sadEmoji, neutralEmoji } from '../assets/icons';

export const Service:FC<ServiceType> = ({service, consume, measure, date, link, historical = 0, others = 0, icon}) => {

  return (
    <>
      <div className="row">
        <span className="color-primary expired-invoice m-auto">Tu factura vence el {date}. <a className="color-primary" href={link} target='blank' >Descárgala Aquí</a></span>
        <div className='card bg-white cols cols-sura col-service'>
          <div className='service service-sura'>
            <img src={icon} alt="" />
            <span className="text">{service}<span className={`consume-service ${service === 'Energía' ? 'consume-service-sura-energy' : 'consume-service-sura-water'}`}>{consume} {measure}</span></span>
          </div>
          <div className={`historical ${historical > 0 ? 'arrow-positive color-primary' : historical < 0 ? 'arrow-negative color-red' : 'arrow-negative color-light-grey'}`}>
            {/* <img className="icon-arrow" src={iconHistorical} alt="" /> */}
            <span className="icon-arrow">{ historical > 0 ? 'BAJÓ' : historical < 0 ? 'SUBIÓ' : 'ESTABLE' }</span>
            <span className="text">{Math.abs(Math.floor(historical))}%</span>
          </div>
        </div>
        <img src={historical > 0 ? applauseEmoji : historical < 0 ? sadEmoji : neutralEmoji} alt="" />
      </div>
    </>
  )
}