import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export const AppProvider = ({ children }) => {

  return (
    <>
      { children }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};