export const pointsToThousands = (number:number = 0) => {
  let numberWithPoints = []
  const decimals = number?.toFixed(1).split('.')
  const arrayNumber = JSON.stringify(parseInt(decimals[0])).split('')
  let counter = 0
  for(let i = (arrayNumber.length - 1); i >= 0; i--) {
    if(i === 0 && arrayNumber[i] === '-') {
    } else {
      if(arrayNumber[i] !== '"') {
        if(counter < 3) {
          numberWithPoints.unshift(arrayNumber[i])
          counter++
        } else {
          numberWithPoints.unshift(arrayNumber[i], '.')
          counter = 1
        }
      }
    }
  }
  if(decimals[1] !== '0' && decimals[1] !== undefined) {
    return `${arrayNumber[0] === '-' ? '-' : ''}${numberWithPoints.toString().replaceAll(',', '')},${decimals[1]}`
  } else {
    return `${arrayNumber[0] === '-' ? '-' : ''}${numberWithPoints.toString().replaceAll(',', '')}`
  }
} 

export const pointsToThousandsSTR = (number:string = '0'): string => {
  let numberWithPoints = []
  const decimals = parseFloat(number).toFixed(1).toString().split('.')
  const arrayNumber = JSON.stringify(parseInt(decimals[0])).split('')
  let counter = 0
  for(let i = (arrayNumber.length - 1); i >= 0; i--) {
      if(arrayNumber[i] !== '"') {
        if(counter < 3) {
          numberWithPoints.unshift(arrayNumber[i])
          counter++
        } else {
          numberWithPoints.unshift(arrayNumber[i], '.')
          counter = 1
        }
      }
  }
  if(decimals[1] !== '0' && decimals[1] !== undefined) {
    return `${numberWithPoints.toString().replaceAll(',', '')},${decimals[1]}`
  } else {
    return `${numberWithPoints.toString().replaceAll(',', '')}`
  }
} 