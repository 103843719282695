import React, { FC, useState, useEffect } from "react";
import { Report } from '../types';

export const AllServices:FC<Report> = ({allServices, totalPrice}) => {

  const [render, setRender] = useState(false);

  useEffect(() => {
    if(allServices?.length || 0 > 0) {
      setTimeout(() => {
        allServices?.forEach((element, index) => {
          const bar = document.getElementById(`bar-${index}`);
          if(bar !== null) bar.style.width = `${element.value}%`;
        })
      }, 500)
    }
  }, [allServices])

  return (
    <>
      <div className="container-graphics">
        <h3 style={{textAlign: 'center', marginBottom: '8px', color: 'var(--color-primary)'}}>ASÍ ES TU GASTO:</h3>
        <ul className="card graphics">
          {
            allServices?.map((element, index) => (
              <li key={`Service-${index}`}>
                <span>{element.name}</span>
                <span className="position-relative text-align container-bar">
                  {/* <span className="bar" id={`bar-${index}`} style={{backgroundColor: `${element.colour}`, marginLeft: `${(100 - element.value) / 2}%`}}></span> */}
                  <span className="bar" id={`bar-${index}`} style={{backgroundColor: `${element.colour}`}}></span>
                  <span className="bar-found"></span>
                </span>
                <span className="price" style={{ color: `${element.colour}`}}>{element.value}%</span>
              </li>
            ))
          }
        </ul>
        <div className="bg-primary payment-total" style={{marginBottom: '2px'}}>
          <span className="text">TOTAL <span>A PAGAR:</span></span>
          <span className="price">${totalPrice !== null ? totalPrice : 0}</span>
        </div>
      </div>
    </>
  )
}