import { Navigate, Outlet } from 'react-router-dom';
import { Main } from '../views';

const routes = () => [
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Navigate to="/report" />
      },
      {
        path: '/report/:id',
        element: <Outlet />,
        children: [
          { index: true, element: <Main /> }
        ],
      }
    ],
  }
];

export default routes;