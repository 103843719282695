import React, { FC } from 'react';
import routes from './routes';
import { useRoutes } from 'react-router-dom';
import { AppProvider } from './providers/App';
import './App.scss';

const App:FC = () => {
  
  const routing = useRoutes(routes());

  return (
    <AppProvider>
      { routing }
    </AppProvider>
  );
}

export default App;
